import { format, differenceInMinutes, differenceInHours, differenceInDays, differenceInSeconds } from 'date-fns';


export const formatNumber = (number: number, fractionDigits = 2) => {
    if (number == null || isNaN(number)) {
        // Return a placeholder or default value if the input is null, undefined, or not a number
        return '-';
    }
    return number.toLocaleString('es', {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
    })
}

export const formatIntNumber = (number: number) => {
    return number.toLocaleString('es', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    })
}

export const formatSecondsToTime = (seconds: number) => {
    const date = new Date(0);
    date.setHours(0, 0, 0);
    date.setSeconds(seconds);
    return format(date, 'HH:mm:ss');
}

export const formatDateDifference = (startDate: Date, endDate: Date) => {
    const ret = [];

    const start = new Date(startDate);
    const end = new Date(endDate);

    const days = differenceInDays(end, start);
    const hours = differenceInHours(end, start) % 24;
    const minutes = differenceInMinutes(end, start) % 60;
    const seconds = differenceInSeconds(end, start) % 60;

    if (days > 0) {
        ret.push(`${days} día${days !== 1 ? 's' : ''}`);
    }

    if (hours > 0) {
        ret.push(`${hours} hora${hours !== 1 ? 's' : ''}`);
    }

    if (minutes > 0) {
        ret.push(`${minutes} minuto${minutes !== 1 ? 's' : ''}`);
    }

    if (ret.length === 0 && seconds > 0) {
        ret.push('< 1 minuto');
    }

    return ret.join(', ');
};
